.welcome-section {
    padding: 50px;
    text-align: center;
    margin: 0px !important;
}

.video-section {
    display: inline-block;
    margin-top: 0px;
}

.demo-video {
    border-radius: 10px;
}

.case-container {
    border-radius: 10px;
    background-color: #f4f4f4;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.list-style {
    background-color: white;
}

.case-item {
    border-bottom: 1px solid #ddd;
}

.case-item:last-child {
    border-bottom: none;
}

.icon-style {
    color: #1976d2;
    margin-right: 10px;
}

.MuiListItem-root:hover {
    background: #F8F8F8;
    cursor: pointer;
}