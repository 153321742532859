.MuiTableBody-root .MuiTableRow-root:hover,
.MuiTimelineItem-root:hover {
    background: #F8F8F8;
    cursor: pointer;
}

.MuiTableRow-root.selected,
.MuiTimelineItem-root.selected {
    background: rgba(25, 118, 210, 0.08);
}

.MuiTimelineOppositeContent-root {
    /* Left justify timeline */
    flex: 0.2 !important;
}

.MuiTimelineDot-root {
    /*increase size of dot */
    padding: 8px !important;
    margin-top: 5px !important;
}

.selectDocumentButtonGroup button {
    border-bottom: 0 !important;
    font-size: 0.7em !important;
    padding: 0 10px;
}

.MuiAccordionSummary-root {
    padding: 0 !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.MuiAccordionSummary-content,
.MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0 5px !important;
}

.MuiAccordionSummary-expandIconWrapper {
    transform: rotate(-90deg) !important;
    cursor: pointer;
    padding: 8px;
}

.MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(0deg) !important;
}

.MuiAccordion-rounded {
    border-radius: 0 !important;
}

.MuiPaper-elevation1 {
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
    box-shadow: none !important;
}

/* Setting table borders to black so that borders are visible when copy pasted */
table,
th,
td {
    border: 1px solid black !important;
}